<template>
  <b-card>
    <b-row>
      <b-col cols="12" md="6">
        <fr-form-input
          :label="$t('Import')"
          v-model="amount"
          append="€"
          type="number"
        />
      </b-col>
      <b-col cols="12" md="6" class="mt-1 mt-md-0">
        <fr-form-input :label="$t('Comentari')" v-model="comment" />
      </b-col>
      <b-col cols="12" md="6" class="mt-1">
        <fr-form-input :label="$t('Nom')" v-model="name" />
      </b-col>
      <b-col cols="12" md="6" class="mt-1">
        <fr-form-input :label="$t('Email')" v-model="email" />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="12" md="2" class="mt-1">
        <fr-form-select
          :label="$t('Idioma')"
          v-model="language"
          :options="languageOptions"
          placeholder="Idioma"
          :clearable="false"
        />
      </b-col>
      <b-col cols="12" md="10" class="mt-1">
        <fr-form-no-input
          :value="tpvLink || $t('tools.tpvGenerator.amountFirstMessage')"
        >
          <span>{{ $t("Enllaç") }}</span>
          <b-link
            v-if="tpvLink"
            v-clipboard:copy="tpvLink"
            v-clipboard:success="onTpvLinkCopied"
            v-clipboard:error="onTpvLinkCopyError"
            class="ml-1"
          >
            <small>copiar</small>
          </b-link>
          <b-link v-if="tpvLink" class="ml-1" @click="onOpenTpvLink">
            <small>obrir</small>
          </b-link>
        </fr-form-no-input>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BLink } from "bootstrap-vue";
import {
  FrFormInput,
  FrFormSelect,
  FrFormNoInput,
} from "@/@foravila-core/components/fr-form";
import { languageOptions } from "@/utils/select-options";
import { getTpvLink, notifyError, notifySuccess } from "@/utils/methods";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    FrFormInput,
    FrFormSelect,
    FrFormNoInput,
  },
  data() {
    return {
      name: null,
      email: null,
      amount: null,
      comment: null,
      language: "en",
    };
  },
  computed: {
    languageOptions() {
      return languageOptions.filter((language) =>
        ["ca", "es", "en", "de"].includes(language.value)
      );
    },
    tpvLink() {
      if (!this.amount) {
        return null;
      }

      return getTpvLink({
        locale: this.language || this.$i18n.locale,
        amount: parseFloat(this.amount).toFixed(2),
        name: this.name,
        comment: this.comment,
        email: this.email,
      });
    },
  },
  methods: {
    onTpvLinkCopied() {
      notifySuccess(
        "Copiat",
        "L'enllaç ha estat copiat correctament al porta-retalls"
      );
    },
    onTpvLinkCopyError() {
      notifyError(
        "Error",
        "Hi ha hagut un problema al intentar copiar l'enllaç al prota-retalls. Intenta-ho de nou"
      );
    },
    onOpenTpvLink() {
      if (!this.tpvLink) {
        return;
      }
      window.open(this.tpvLink, "_blank");
    },
  },
};
</script>
